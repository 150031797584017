import { Component, effect, inject } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CartHeader } from '@lobos/common-v3';
import { CreateCartDto } from '@lobos/gateway-v3';
import { TranslocoDirective } from '@jsverse/transloco';
import { InputComponent } from '../form/input/input.component';
import { GetErrorPipe } from '../../pipes/get-error.pipe';
import { CustomSelectComponent } from '../form/custom-select/custom-select.component';
import { ShippingAddressListPipe } from '../../pipes/shipping-address-list.pipe';
import { TextareaComponent } from '../form/textarea/textarea.component';
import { TextButtonComponent } from '../button/text-button/text-button.component';
import { GlobalStore } from '../../store/global.store';
import { AddressService } from '../../services/address/address.service';
import { AddressHelperService } from '../../services/address/address-helper.service';

export interface CartHeaderData {
  cartHeader: CartHeader | null | undefined;
}

@UntilDestroy()
@Component({
  selector: 'app-add-edit-cart-modal',
  templateUrl: './add-edit-cart-modal.component.html',
  styleUrls: ['./add-edit-cart-modal.component.scss'],
  standalone: true,
  imports: [
    TranslocoDirective,
    ReactiveFormsModule,
    InputComponent,
    GetErrorPipe,
    CustomSelectComponent,
    ShippingAddressListPipe,
    TextareaComponent,
    TextButtonComponent,
  ],
})
export class AddEditCartModalComponent {
  store = inject(GlobalStore);
  dialogData: CartHeaderData = inject(MAT_DIALOG_DATA);
  dialogRef = inject(MatDialogRef);
  addressService = inject(AddressService);
  addressHelperService = inject(AddressHelperService);

  addresses = this.addressService.getAddresses(1, 100).result;

  form = new FormGroup({
    sCartName: new FormControl<string>('', {
      nonNullable: true,
      validators: [Validators.required],
    }),
    lngAddressID: new FormControl<number | null>(null),
    sMemo: new FormControl<string>('', { nonNullable: true }),
  });

  constructor() {
    if (this.dialogData.cartHeader) {
      this.form.patchValue({
        ...this.dialogData.cartHeader,
        lngAddressID: (this.dialogData.cartHeader.oDeliveryAddress.lngAddressID as number) || null,
      });

      effect(() => {
        const address = this.addresses().data?.data?.find((address) =>
          this.addressHelperService.isEqual(address, this.dialogData.cartHeader!.oDeliveryAddress),
        );
        if (address) {
          this.form.patchValue({ lngAddressID: address.lngAddressID as number });
        }
      });
    }
  }

  public save(): void {
    this.form.markAllAsTouched();
    if (!this.form.valid) {
      return;
    }

    if (this.dialogData.cartHeader) {
      this.store.updateCart(
        {
          oSalesMaster: {
            ...this.dialogData.cartHeader,
            ...this.form.value,
            shtCompleteDelivery: 0, //0 as long as this feature is not implemented
          },
        },
        this.dialogData.cartHeader.lngOrderID,
      );
    } else {
      this.store.createCart({
        sContactName: this.store.user()!.oContact.sContactName,
        lngCampaignID: 0,
        lngProjectId: 0,
        ...this.form.value,
        oDeliveryAddress: this.addresses().data?.data?.find((address) => address.lngAddressID === this.form.value.lngAddressID),
      } as CreateCartDto);
    }

    this.dialogRef.close();
  }
}
